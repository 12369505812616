/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import MainTable from "../../../GenericComponents/MainTable";
import { updateData } from "../../../../Services/updateData";
import { urlEnum } from "../../../../Utils/UrlEnum";
import { postData } from "../../../../Services/postData";
import styles from "../../../../Styles/dashboard.module.css";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { PurchaseOrderModel } from "../../../../Utils/Models";
import { styleForTableWithTabs } from "../../../../Pages/Users";
import PurchaseOrder from "./PurchaseOrder";
import { Chip } from "@mui/material";
import moment from "moment";
import { euFormatForDateTime } from "../../../../Utils/Config";
import { PurchaseOrderStatuses } from "../../../../Utils/AutocompleteUtils";
import GenericConfirmModal from "../../../GenericComponents/GenericConfirmModal";
import ShowPurchaseOrder from "./ShowPurchaseOrder";

type PurchaseOrdersMainTableProps = {
  type: string;
};
export default function PurchaseOrdersMainTable(
  props: PurchaseOrdersMainTableProps
) {
  const { type } = props;
  const [openEdit, setOpenEdit] = useState(false);
  const [showPurchaseOrder, setShowPurchaseOrder] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [editItem, setEditItem] = useState<PurchaseOrderModel | null>(null);
  const [receivedOrder, setReceivedOrder] = useState({
    open: false,
    id: null,
  });

  const purchaseOrdersHeader = [
    {
      label: Vocabulary.purchaseOrderNumber,
      name: "purchaseOrderNumber",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.referenceNumber,
      name: "referenceNumber",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.inventory} / ${Vocabulary.location}`,
      name: "inventory",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value?.name} <br /> {value?.location?.name}
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.products,
      name: "products",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value.map((product: any, key: number) => (
                <span key={`${product._id}_${product.name}`}>
                  {key < 2 ? (
                    <Chip
                      label={`${product.product.code} - ${
                        product.product.name.length > 7
                          ? `${product.product.name.substring(0, 7)}...`
                          : product.product.name
                      }`}
                      style={{
                        margin: 5,
                        backgroundColor: "#404a694d",
                      }}
                    />
                  ) : key === 2 ? (
                    <Chip
                      key={value.id}
                      label="..."
                      style={{
                        margin: 5,
                        backgroundColor: "#404a694d",
                      }}
                    />
                  ) : null}
                </span>
              ))}
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.vendor,
      name: "vendor",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value?.company}</>;
        },
      },
    },
    {
      label: Vocabulary.date,
      name: "createdAt",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{moment(value).format(euFormatForDateTime)}</>;
        },
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: PurchaseOrderModel | null, showItem?: boolean) {
    setShouldUpdate(false);
    if (item) {
      setEditItem(item);
      if (showItem) {
        setShowPurchaseOrder(true);
      } else {
        setOpenEdit(true);
      }
    } else {
      setEditItem(null);
      if (showItem) {
        setShowPurchaseOrder(false);
      } else {
        setOpenEdit(!openEdit);
      }
    }
  }
  /**
   *
   * @param purchaseOrder
   */
  function addOrUpdatePurchaseOrder(purchaseOrder: PurchaseOrderModel | null) {
    if (purchaseOrder?._id) {
      updateData(
        `${urlEnum.purchaseOrders}/${purchaseOrder._id}`,
        purchaseOrder
      )
        .then((res) => {
          if (res) {
            setShouldUpdate(true);
            setOpenEdit(false);
          }
        })
        .catch((err) => {
          setOpenEdit(false);
        });
    } else {
      postData(urlEnum.purchaseOrders, purchaseOrder)
        .then((res: any) => {
          if (res) {
            setShouldUpdate(true);
            setOpenEdit(false);
          }
        })
        .catch((err) => {
          setOpenEdit(false);
        });
    }
  }

  /**
   *
   */
  function receiveOrder() {
    updateData(`${urlEnum.purchaseOrders}/${receivedOrder.id}/receive`, {})
      .then((res) => {
        if (res) {
          setReceivedOrder({ open: false, id: null });
          setShouldUpdate(true);
        }
      })
      .catch((err) => {
        setReceivedOrder({ open: false, id: null });
      });
  }

  /**
   *
   */
  function receiveSelectedProducts(
    id: string | null,
    selectedProducts: string[]
  ) {
    postData(`${urlEnum.purchaseOrders}/${id}`, selectedProducts).then(
      (response) => {
        if (response) {
          setShowPurchaseOrder(false);
          setShouldUpdate(true);
        }
      }
    );
  }

  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={urlEnum.purchaseOrders}
        titleDelete={Vocabulary.deletePurchaseOrder}
        textDelete={Vocabulary.deleteConfirmationPurchaseOrder}
        header={purchaseOrdersHeader}
        tableTitle={
          type === PurchaseOrderStatuses.open
            ? Vocabulary.openPurchaseOrdersList
            : Vocabulary.closedPurchaseOrdersList
        }
        shouldUpdate={shouldUpdate}
        hideOptionButtons={type === PurchaseOrderStatuses.closed}
        hideAddButton={type === PurchaseOrderStatuses.closed}
        style={styleForTableWithTabs}
        showItem={true}
        anotherButtonsOptions={{
          icon: <TaskAltIcon />,
          title: Vocabulary.receivedOrder,
          condition: (item) => item.status === PurchaseOrderStatuses.open,
          action: (item: any) => {
            setShouldUpdate(false);
            setReceivedOrder({ open: true, id: item._id });
          },
        }}
        handleEdit={handleEdit}
      />
      {openEdit && type === PurchaseOrderStatuses.open ? (
        <PurchaseOrder
          editPurchaseOrder={editItem}
          open={openEdit}
          addOrUpdatePurchaseOrder={(purchaseOrder) =>
            addOrUpdatePurchaseOrder(purchaseOrder)
          }
          onClose={() => handleEdit(null)}
        />
      ) : null}
      {showPurchaseOrder && editItem ? (
        <ShowPurchaseOrder
          editPurchaseOrder={editItem}
          open={showPurchaseOrder}
          onClose={() => handleEdit(null, true)}
          receiveSelectedProducts={receiveSelectedProducts}
        />
      ) : null}

      {receivedOrder.open ? (
        <GenericConfirmModal
          title={Vocabulary.receivedOrder}
          text={Vocabulary.receivedOrderConfirmationMessage}
          openDeleteModal={receivedOrder.open}
          onClose={() => setReceivedOrder({ open: false, id: null })}
          deleteItem={receiveOrder}
        />
      ) : null}
    </div>
  );
}

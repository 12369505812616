/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  calcSubTotal,
  calcSubTotalWithTVA,
  handleChangeAutocomplete,
  onChangeTextField,
} from "../../Utils/Utils";
import { LineModel, TaxRateModel } from "../../Utils/Models";
import theme from "../../Themes/Theme";
import ExpendButtons from "../GenericComponents/ExpendButtons";
import ProductsAutocomplete from "../Products/ProductsAutocomplete";
import GenericModal from "../GenericComponents/GenericModal";
import useCustomMemo from "../GenericComponents/useCustomMemo";

type ProductPanelProps = {
  index: number;
  editLine: LineModel | null;
  handleChange: (line: LineModel, index: number) => void;
  deleteLine: (index: number) => void;
};

export default function ProductPanel(props: ProductPanelProps) {
  const { index, editLine, handleChange, deleteLine } = props;
  const cache = useCustomMemo();
  const taxRates = cache.taxRates || [];
  const [line, setLine] = useState(new LineModel());
  const [showMore, setShowMore] = useState(false);
  const [openProductInventories, setOpenProductInventories] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);

  /**
   *
   */
  useEffect(() => {
    if (editLine) {
      setLine(editLine);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editLine]);

  /**
   *
   */
  function makeSubTotals(newLine: LineModel) {
    newLine.total0 = calcSubTotal(
      newLine.discount,
      newLine?.quantity,
      newLine.price
    );
    newLine.total = calcSubTotalWithTVA(newLine.total0, newLine.vat);
    newLine.totalRemaining0 = newLine.total0;
    newLine.totalRemaining = newLine.total;
    newLine.quantityCharged = newLine.quantity;
    newLine.quantityDispatched = newLine.quantity;
    handleChange(newLine, index);
  }

  /**
   *
   * @param event
   * @param value
   */
  function handleChangeProduct() {
    const newLine: any = Object.assign({}, line);
    newLine.product = selectedProduct;
    newLine.price = selectedProduct.stockPrice || 0;
    newLine.vat = selectedProduct.vat || 0;
    newLine.quantity = selectedProduct.inventory.quantity || 0;
    newLine.name = selectedProduct.name;
    newLine.code = selectedProduct.code;
    newLine.priceRange = "STOCK";
    newLine.quantityCharged = selectedProduct.inventory.quantity || 0;
    newLine.quantityDispatched = selectedProduct.inventory.quantity || 0;
    makeSubTotals(newLine);
    newLine.totalCharged0 = 0;
    newLine.totalCharged = 0;
    newLine.totalRemaining0 = newLine.total0;
    newLine.totalRemaining = newLine.total;
    setOpenProductInventories(false);
    setSelectedProduct(null);
  }

  /**
   *
   */
  function selectProduct(event: any, value: any) {
    if (value) {
      setSelectedProduct(value);
      setOpenProductInventories(true);
    }
  }

  return (
    <div style={{ padding: "10px 5px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <ProductsAutocomplete
            parentProduct={line.product}
            handleChangeProductInParent={(event: any, value: any) => {
              selectProduct(event, value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={1.1}>
          <TextField
            id="discount"
            name="discount"
            size="small"
            variant="standard"
            label={Vocabulary.discount}
            value={line.discount}
            onChange={(event: any) => {
              const newLine: any = onChangeTextField(event, line);
              makeSubTotals(newLine);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={1.1}>
          <TextField
            id="quantity"
            name="quantity"
            type="number"
            size="small"
            variant="standard"
            label={Vocabulary.quantity}
            value={line.quantity}
            onChange={(event: any) => {
              const newLine: any = onChangeTextField(event, line);
              makeSubTotals(newLine);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={1.6}>
          <TextField
            id="price"
            name="price"
            size="small"
            variant="standard"
            label={Vocabulary.price}
            value={line.price}
            onChange={(event: any) => {
              const newLine: any = onChangeTextField(event, line);
              makeSubTotals(newLine);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={1.2}>
          <FormControl fullWidth>
            <InputLabel>{Vocabulary.vat}</InputLabel>
            <Select
              size="small"
              id="vat"
              variant="standard"
              value={line.vat || ""}
              label={Vocabulary.vat}
              fullWidth
              onChange={(event: SelectChangeEvent) => {
                const newLine: any = Object.assign({}, line);
                newLine.vat = event.target.value;
                makeSubTotals(newLine);
              }}
            >
              {taxRates.map((taxRate: TaxRateModel) => {
                return (
                  <MenuItem key={taxRate._id} value={taxRate.value}>
                    {taxRate.value}
                  </MenuItem>
                );
              })}
              {!taxRates.some(
                (taxRate: TaxRateModel) => taxRate.value === line.vat
              ) &&
                line.vat && (
                  <MenuItem disabled value={line.vat}>
                    {line.vat}
                  </MenuItem>
                )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={1.6}>
          <TextField
            id="total0"
            name="total0"
            size="small"
            variant="standard"
            label={Vocabulary.total0}
            value={line.total0}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={1.7}>
          <TextField
            id="total"
            name="total"
            size="small"
            variant="standard"
            label={Vocabulary.total}
            value={line.total}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={0.7} style={{ paddingLeft: 0 }}>
          <Button onClick={() => setShowMore(!showMore)}>
            <ExpendButtons
              showMore={showMore}
              handleChangeShowMore={() => null}
              style={null}
            />
          </Button>
        </Grid>
      </Grid>
      <Collapse in={showMore}>
        {
          <Grid container spacing={2} style={{ padding: 20 }}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="name"
                name="name"
                label={Vocabulary.name}
                fullWidth
                variant="standard"
                value={line.name}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="code"
                name="code"
                label={Vocabulary.code}
                fullWidth
                variant="standard"
                value={line.code}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="priceRange"
                name="priceRange"
                label={Vocabulary.priceRange}
                fullWidth
                variant="standard"
                value={line.priceRange}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="quantityCharged"
                name="quantityCharged"
                label={Vocabulary.quantityCharged}
                fullWidth
                variant="standard"
                value={line.quantityCharged}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="quantityDispatched"
                name="quantityDispatched"
                label={Vocabulary.quantityDispatched}
                fullWidth
                variant="standard"
                value={line.quantityDispatched}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="notes"
                name="notes"
                label={Vocabulary.notes}
                fullWidth
                variant="standard"
                value={line.notes}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="serialNumber"
                name="serialNumber"
                label={Vocabulary.serialNumber}
                fullWidth
                variant="standard"
                value={line.serialNumber || ""}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="originalSerialNumber"
                name="originalSerialNumber"
                label={Vocabulary.originalSerialNumber}
                fullWidth
                variant="standard"
                value={line.originalSerialNumber || ""}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="imeiNumber"
                name="imeiNumber"
                label={Vocabulary.imeiNumber}
                fullWidth
                variant="standard"
                value={line.imeiNumber || ""}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="purchasePrice"
                name="purchasePrice"
                label={Vocabulary.purchasePrice}
                fullWidth
                variant="standard"
                value={line.purchasePrice}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="totalCharged0"
                name="totalCharged0"
                label={Vocabulary.totalCharged0}
                fullWidth
                variant="standard"
                value={line.totalCharged0}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="totalCharged"
                name="totalCharged"
                label={Vocabulary.totalCharged}
                fullWidth
                variant="standard"
                value={line.totalCharged}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="totalRemaining0"
                name="totalRemaining0"
                label={Vocabulary.totalRemaining0}
                fullWidth
                variant="standard"
                value={line.totalRemaining0}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                size="small"
                id="totalRemaining"
                name="totalRemaining"
                label={Vocabulary.totalRemaining}
                fullWidth
                variant="standard"
                value={line.totalRemaining}
                onChange={(event: any) =>
                  handleChange(onChangeTextField(event, line), index)
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ textAlign: "right" }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                }}
                onClick={() => {
                  deleteLine(index);
                  setShowMore(false);
                }}
              >
                {Vocabulary.remove}
              </Button>
            </Grid>
          </Grid>
        }
      </Collapse>
      <GenericModal
        open={openProductInventories}
        onClose={() => {
          setOpenProductInventories(false);
          setSelectedProduct(null);
        }}
        title={Vocabulary.chooseInventory}
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: 20,
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              style={{
                borderRadius: 4,
                color: "white",
                backgroundColor: theme.palette.errorColor?.main,
              }}
              onClick={() => {
                setOpenProductInventories(false);
                setSelectedProduct(null);
              }}
            >
              {Vocabulary.no}
            </Button>
            <Button
              variant="contained"
              style={{
                borderRadius: 4,
                color: "white",
                backgroundColor: theme.palette.primary?.main,
              }}
              disabled={!selectedProduct || !selectedProduct.inventory}
              onClick={() => {
                handleChangeProduct();
              }}
            >
              {Vocabulary.yes}
            </Button>
          </div>
        }
        style={{ textAlign: "center" }}
        styleTitle={{
          fontSize: 18,
          color: "white",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Autocomplete
          size="small"
          id="inventories"
          freeSolo={false}
          options={
            selectedProduct && selectedProduct.inventories
              ? selectedProduct.inventories
              : []
          }
          value={
            selectedProduct && selectedProduct.inventory
              ? selectedProduct.inventory
              : null
          }
          getOptionLabel={(option: any) => option.inventory.name}
          isOptionEqualToValue={(option, value) =>
            option.inventory._id === value.inventory._id
          }
          onChange={(event: any, value: any) => {
            setSelectedProduct({ ...selectedProduct, inventory: value });
          }}
          style={{ border: "1px solid #ccc", borderRadius: 4 }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              placeholder={Vocabulary.inventories}
              variant="outlined"
            />
          )}
        />
      </GenericModal>
    </div>
  );
}

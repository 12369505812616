/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */
import { Button, Divider, Menu, MenuItem } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useContext, useEffect, useState } from "react";
import { OrderContext } from "../../Contexts/OrderContext";
import PaymentIcon from "@mui/icons-material/Payment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Vocabulary } from "../../Utils/Vocabulary";
import InvoiceModal from "./InvoiceModal";
import { toast } from "react-toastify";
import { invoiceTypes } from "../../Utils/AutocompleteUtils";
import { addOrUpdateOrder } from "./Order";
import { InvoiceModel } from "../../Utils/Models";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { download, getDocExtension } from "../../Utils/Utils";

export default function OrderInvoices() {
  const orderContext = useContext(OrderContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openChargeModal, setOpenChargeModal] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const open = Boolean(anchorEl);

  const [invoices, setInvoices] = useState<Array<InvoiceModel>>([]);

  useEffect(() => {
    getInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderContext.order]);

  /**
   *
   */
  async function getInvoices() {
    let orderNumber = orderContext.order.fixablyId;
    if (!orderNumber) {
      orderNumber = orderContext.order.orderNumber;
    }
    const res: any = await getData(`${urlEnum.invoices}/order/${orderNumber}`);
    if (res && res.data && res.data.result) {
      setInvoices(res.data.result);
    }
  }

    /**
   *
   * @param attachment
   */
    async function handleClickFile(fileName: any) {
      const token = localStorage.getItem("access_token");
      const type = getDocExtension(fileName.split(".").pop());
  
      fetch(`${urlEnum.invoices}/download/${fileName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.blob())
        .then((response) => {
          download(response, fileName.split("/").pop(), type);
        });
    }

  /////////////////////////// SETTINGS MENU ///////////////////////////

  /**
   *
   * @param event
   */
  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }
  /**
   *
   */
  function handleCloseMenu() {
    setAnchorEl(null);
  }
  /////////////////////////// END SETTINGS MENU ///////////////////////////
  return (
    <>
      <Button
        size="medium"
        variant="outlined"
        style={{
          color: "#333",
          borderColor: "#333",
          padding: "5px 0px",
          marginRight: 15,
        }}
        onClick={handleClick}
      >
        <InsertDriveFileIcon fontSize="medium" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={async () => {
            const lines =
              orderContext.order?.lines.filter(
                (line) => +line.totalCharged !== +line.total
              ) || [];
            if (lines.length === 0) {
              toast.error(Vocabulary.noProductsToCharge);
              return;
            }
            await addOrUpdateOrder(orderContext);
            setOpenChargeModal(true);
          }}
          disabled={orderContext.order?._id ? false : true}
        >
          <PaymentIcon style={{ marginRight: 10 }} /> {Vocabulary.receipt}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={async () => {
            const lines =
              orderContext.order?.lines.filter(
                (line) => +line.totalCharged !== +line.total
              ) || [];
            if (lines.length === 0) {
              toast.error(Vocabulary.noProductsToInvoice);
              return;
            }
            await addOrUpdateOrder(orderContext);
            setOpenInvoiceModal(true);
          }}
          disabled={orderContext.order?._id ? false : true}
        >
          <MailOutlineIcon style={{ marginRight: 10 }} /> {Vocabulary.invoice}
        </MenuItem>
        <Divider />
        {invoices
          .filter((invoice: InvoiceModel) => invoice.pathName)
          .map((invoice: InvoiceModel) => (
            <span key={invoice._id}>
              <MenuItem onClick={() => handleClickFile(invoice.pathName)}>
                {invoice.pathName}
              </MenuItem>
              <Divider />
            </span>
          ))}
      </Menu>
      {openChargeModal ? (
        <InvoiceModal
          type={invoiceTypes.receipt}
          open={openChargeModal}
          title={Vocabulary.receipt}
          onClose={() => setOpenChargeModal(false)}
        />
      ) : null}

      {openInvoiceModal ? (
        <InvoiceModal
          type={invoiceTypes.invoice}
          open={openInvoiceModal}
          title={Vocabulary.invoice}
          onClose={() => setOpenInvoiceModal(false)}
        />
      ) : null}
    </>
  );
}

/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, useMediaQuery } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { OrderContext } from "../../Contexts/OrderContext";
import { useContext } from "react";
import styles from "../../Styles/dashboard.module.css";
import OrderSettingsButton from "./OrderSettingsButton";
import OrderInvoices from "./OrderInvoices";
import { addOrUpdateOrder } from "./Order";

export const priorityArray = [
  {
    name: "NORMAL",
    label: Vocabulary.normal,
    icon: <KeyboardArrowRightIcon />,
  },
  {
    name: "HIGHER",
    label: Vocabulary.higher,
    icon: <KeyboardArrowUpIcon />,
  },
  {
    name: "HIGHEST",
    label: Vocabulary.highest,
    icon: <KeyboardDoubleArrowUpIcon />,
  },
];

export default function OrderHeader() {
  const phoneStyle = useMediaQuery("(max-width:800px)");
  const orderContext = useContext(OrderContext);

  return (
    <div
      id="orderTopMenu"
      className={phoneStyle ? styles.orderHeaderMobile : styles.orderHeader}
    >
      <div>
        <p
          style={{
            color: "#008470",
            fontWeight: 600,
            margin: "5px 0px 0px 0px",
          }}
        >
          {orderContext.order.fixablyId
            ? `${Vocabulary.orderNumber}: ${orderContext.order.fixablyId}`
            : ""}
        </p>
        <div
          style={{
            display: "block",
            margin: "10px 0px 0px 0px",
            fontWeight: 300,
          }}
        >
          {orderContext.order.isClosed ? (
            <p
              style={{
                color: "red",
                margin: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <LockIcon style={{ marginRight: 10 }} />
              <span style={{ verticalAlign: "middle" }}>
                {Vocabulary.orderIsClosed}
              </span>
            </p>
          ) : (
            <p
              style={{
                color: "green",
                margin: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <LockOpenIcon style={{ marginRight: 10 }} />
              <span style={{ verticalAlign: "middle" }}>
                {Vocabulary.orderIsOpen}
              </span>
            </p>
          )}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <Button
          size="medium"
          variant="contained"
          style={{
            marginRight: 15,
          }}
          onClick={() => addOrUpdateOrder(orderContext)}
        >
          {Vocabulary.saveOrder}
        </Button>
        <OrderInvoices />
        <OrderSettingsButton />
      </div>
    </div>
  );
}

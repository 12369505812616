import moment, { Moment } from "moment";
import { VariantProps } from "../Components/Products/ProductVariants";
import { PurchaseOrderStatuses } from "./AutocompleteUtils";

/* eslint-disable @typescript-eslint/no-explicit-any */
export class LoginModel {
  username: string | null = "";
  password: string | null = "";
}

export class UserModel {
  _id: string | null = null;
  lastName: string | null = "";
  firstName: string | null = "";
  email: string | null = "";
  username: string | null = "";
  password: string | null = "";
  confirmPassword: string | null = "";
  roleId: RoleModel | null = null;
  fixablyId: number | null = null;
  isEditable = true;
  isActive = true;
}

export class DepartmentModel {
  id = null;
  name: string | null = "";
}

export interface Task {
  id: number;
  title: string;
  description: string;
  columnId: number;
  priority: string;
  startDate: string;
  endDate: string;
  project: string;
}

export class WorkflowModel {
  _id: string | null = null;
  fixablyId: number | null = null;
  name: string | null = "";
  description: string | null = "";
  enabled = false;
  cost = 0;
  time: string | null = "00:00:00";
  statuses: Array<any> = [];
  parentId: string | null = null;
  children: WorkflowModel[] = [];
}

export class WorkflowArrayFromStepModel {
  workflowId: string | null = null;
  time: string | null = "00:00:00";
}
export class WorkflowStepModel {
  _id: string | null = null;
  fixablyId: number | null = null;
  name: string | null = "";
  internalName: string | null = "";
  identifier: string | null = null;
  type: string | null = "";
  notes: string | null = null;
  addToCheckIn = false;
  workflows: Array<WorkflowArrayFromStepModel> = [];
  defaultValues: Array<any> = [];
}

export class ProductStockModel {
  _id?: string | null = null;
  inventory: InventoryModel | null = null;
  quantity = 0;
  price = 0;
  percentageVAT = "0";
  isNew = false;
}
export class ProductModel {
  _id: string | null = null;
  fixablyId: number | null = null;
  shopifyId: number | null = null;
  code: string | null = "";
  partNumber: string | null = "";
  ean: string | null = "";
  name: string | null = "";
  description: string | null = null;
  family: string | null = null;
  line: string | null = null;
  version: string | null = null;
  type: number | null = 0;
  typeString: string | null = "";
  category: number | null = 0;
  categoryString: string | null = "";
  vat: string | null = "";
  web: number | null = 0;
  purchaseStockPrice: string | null = "";
  purchaseExchangePrice: string | null = "";
  stockPrice: string | null = "";
  exchangePrice: string | null = "";
  manufacturer: string | null = "";
  markup: string | null = "";
  serialized = false;
  fixed = false;
  tags: Array<string> = [];
  eeeCode: string | null = null;
  provider: string | null = null;
  identifier: string | null = null;
  customFields: Array<{ details: string; garantie: string }> = [
    { details: "", garantie: "" },
  ];
  visibleOnPrintouts = false;
  images: Array<string> = [];
  options: Array<AttributeModel> = [];
  variants: Array<VariantProps> = [];
  files: Array<any> = [];
  inventories: Array<ProductStockModel> = [];
}

export class DeviceModel {
  _id: string | null = null;
  fixablyId: number | null = null;
  serialNumber: string | null = "";
  imeiNumber1: string | null = "";
  imeiNumber2: string | null = "";
  name: string | null = "";
  configuration: string | null = "";
  model: string | null = "";
  code: string | null = "";
  family: string | null = null;
  brand: string | null = "";
  distributor: string | null = null;
  purchaseCountry: string | null = null;
  purchaseDate: string | Moment | null = moment();
  isActivationLocked = false;
  isCarrierLocked = false;
  isVintage = false;
  isObsolete = false;
  isLoaner = false;
  tags: Array<string> = [];
  customFields: Array<string> = [];
  productImageUrl: string | null = "";
  createdAt?: string | null = "";
}

export class AddressModel {
  id?: number | null = null;
  name: string | null = "";
  address1: string | null = "";
  address2: string | null = "";
  address3: string | null = "";
  zip: string | null = "";
  city: string | null = "";
  state: string | null = "";
  country: string | null = "";
  identifier: string | null = "";
}

export class CustomerModel {
  _id: string | null = null;
  fixablyId: number | null = null;
  firstName: string | null = "";
  lastName: string | null = "";
  company: string | null = null;
  phone: string | null = "";
  email: string | null = "";
  shippingAddress: AddressModel | null = null;
  billingAddress: AddressModel | null = null;
  allShippingAddresses: Array<AddressModel> = [];
  businessId: string | null = null;
  vatNumber: string | null = "";
  group: string | null = null;
  language: string | null = null;
  provider: string | null = null;
  identifier: string | null = null;
  parent: string | null = null;
  priority: number | null = 0;
  notes: string | null = null;
  tags: Array<string> = [];
  roles: Array<any> = [];
  customFields: Array<string> = [];
  defaultPaymentTerm: string | null = null;
  allowShareEmail = true;
}
export class OpeningHoursModel {
  aa: string | null = "";
  de: string | null = "";
  en: string | null = "";
  fr: string | null = "";
  it: string | null = "";
  help: string | null = "";
}
export class LocationModel {
  _id?: number | null = null;
  fixablyId: number | null = null;
  name: string | null = "";
  email: string | null = "";
  phone: string | null = null;
  country: string | null = "";
  address: string | null = "";
  zip: string | null = "";
  state: string | null = null;
  city: string | null = "";
  tat: number | null = 0;
  openingHours: OpeningHoursModel | null = null;
  deliveries: Array<string> = [];
  longitude: string | null = null;
  latitude: string | null = null;
}

export class DeliveryModel {
  _id: string | null = null;
  fixablyId: number | null = null;
  name: string | null = "";
  shortName: string | null = "";
  description: string | null = null;
}

type Contract = {
  fullName: string | null;
  company: string | null;
  phoneNumber: string | null;
  emailAddress: string | null;
  method: string | null;
};
export class TimeTracking {
  startTime: string | number = 0;
  endTime: string | number | null = null;
  userId: UserModel | null = null;
  isManual = false;
  index?: number | null = null;
}

export class Step {
  stepId: WorkflowStepModel | null = null;
  description: string | null = null;
  images: Array<string> = [];
  files?: Array<any> = [];
  customStepValue: any;
  timeTracking: Array<TimeTracking> = [];
}

export class OrderModel {
  _id: string | null = null;
  fixablyId: number | null = null;
  orderNumber: number | null = 0;
  identifier: string | null = null;
  isClosed = true;
  isInside = false;
  isDraft = false;
  internalLocation: string | null = "CUSTOMER";
  transitOrigin: string | null = null;
  currentInsideTime: string | null = null;
  totalInsideTime: number | null = 0;
  priority: string | null = "NORMAL";
  tags: Array<string> = [];
  notes: Array<any> = [];
  orderedBy: CustomerModel | null = null;
  handledBy: UserModel | null = null;
  reference: string | null = "";
  internalReference: string | null = "";
  magic: string | null = null;
  customReference: string | null = null;
  customReference2: string | null = null;
  device: DeviceModel | null = null;
  customer: CustomerModel | null = null;
  insuranceCompany: string | null = null;
  contact: Contract | null = null;
  status: StatusModel | null = null;
  workflow: WorkflowModel | null = null;
  location: string | null = null;
  store: string | null = null;
  shipments: Array<any> = [];
  lines: Array<any> = [new LineModel()];
  workflowSteps: Array<Step> = [];
  total: number | null = 0;
  total0: number | null = 0;
  totalCharged: number | null = 0;
  totalRemaining: number | null = 0;
  customFields: Array<string> = [];
  createdAt?: string | null = "";
}

export class LineModel {
  _id: string | null = null;
  fixablyId: number | null = null;
  code: string | null = "";
  name: string | null = "";
  product: ProductModel | null = null;
  priceRange: string | null = "";
  quantity: string | null = "0";
  quantityCharged: string | null = "";
  quantityDispatched: string | null = "";
  price: string | null = "0";
  vat: string | null = "0";
  discount: string | null = "0";
  notes: string | null = "";
  isHidden = false;
  serialNumber: string | null = null;
  originalSerialNumber: string | null = null;
  imeiNumber: string | null = null;
  purchasePrice: string | null = "";
  total0: string | 0 = "0";
  total: string | 0 = "0";
  totalCharged0: string | 0 = "";
  totalCharged: string | 0 = "";
  totalRemaining0: string | 0 = "";
  totalRemaining: string | 0 = "";
  customFields: Array<string> = [];
}

export class NotesModel {
  _id: string | null = null;
  fixablyId: number | null = null;
  type: string | null = "";
  title: string | null = "";
  text: string | null = "";
  status = 0;
  visibility: string | any | null = null;
  report = 0;
  createdBy: UserModel = new UserModel();
  attachments: Array<string> = [];
  emailDetails: any = null;
  createdAt?: string;
}

export class AttributeModel {
  _id?: string | null = null;
  name: string | null = "";
  values: Array<string> = [""];
}
export class CategoryModel {
  _id?: string | null = null;
  name: string | null = "";
  description: string | null = "";
}

export class PackageModel {
  weight = 1;
  height = 10;
  width = 10;
  label: string | null = null;
  trackingNumber: string | null = null;
  trackingUrl: string | null = null;
  status: string | null = null;
  orders: Array<string> = [];
}

export class ShipmentModel {
  _id: string | null = null;
  fixablyId: number | null = null;
  trackingNumber: string | null = "";
  carrier: string | null = "";
  reference: string | null = null;
  sender: string | null = null;
  senderAddress: AddressModel | null = new AddressModel();
  recipient: CustomerModel | null = null;
  recipientAddress: AddressModel | null = new AddressModel();
  senderLocation: LocationModel | null = null;
  recipientLocation: LocationModel | null = null;
  senderStore: string | null = null;
  recipientStore: string | null = null;
  serviceCode: string | null = null;
  status: string | null = "";
  packages: Array<PackageModel> = [new PackageModel()];
  createdAt?: string | null = "";
}

export class StatusModel {
  _id: string | null = null;
  fixablyId: number | null = null;
  name: string | null = "";
  description: string | null = "";
  type: string | null = "";
  liable: string | null = "";
  workflowId: WorkflowModel | null = null;
  isEnabled = true;
  isDefault = true;
}

export class RoleModel {
  _id: string | null = null;
  fixablyId: number | null = null;
  name: string | null = "";
  displayName: string | null = "";
}

export class EmailTemplateModel {
  _id: string | null = null;
  name: string | null = "";
  subject: string | null = "";
  html: any | null = "";
  status: string | null = null;
  cc: string | null = "";
}

export class DocumentTemplateModel {
  _id: string | null = null;
  name: string | null = "";
  filePath: string | null = "";
  type: string | null = "";
  documentInformation: any;
  file: any;
}

export class TemplateStatusModel {
  _id: string | null = null;
  name: string | null = "";
  color = "#FFF";
  sortNumber: number | null = 0;
}

export class InventoryModel {
  _id: string | null = null;
  name: string | null = "";
  description: string | null = "";
  defaultInventory = false;
  location: LocationModel | null = null;
  createdAt: string | null = "";
}

export class StockTransferProductModel {
  product: ProductModel | null = null;
  quantity: number | null = 0;
  price: number | null = null;
  status = PurchaseOrderStatuses.open;
  maxQuantity?: number | null = 0;
}
export class StockTransferModel {
  _id: string | null = null;
  from: InventoryModel | null = null;
  to: InventoryModel | null = null;
  status: string | null = null;
  products: Array<StockTransferProductModel> = [];
  comments: string | null = "";
  createdAt?: string | null = "";
}

export class PurchaseOrderModel {
  _id: string | null = null;
  purchaseOrderNumber: number | null = 0;
  referenceNumber: string | null = "";
  inventory: InventoryModel | null = null;
  vendor: CustomerModel | null = null;
  status = PurchaseOrderStatuses.open;
  products: Array<StockTransferProductModel> = [];
  createdAt?: string | null = "";
}

export class TaxRateModel {
  _id: string | null = null;
  name: string | null = "";
  value = "";
  defaultValue = false;
}

export class FiltersModel {
  startDate: Moment = moment().subtract(1, "months");
  endDate: Moment = moment();
  order?: number | string | null = "";
  orderStatus?: any = null;
  user?: any = null;
  customer?: any = null;
  vendor?: any = null;
  device?: any = null;
  workflow?: any = null;
  workflowStatus?: any = null;
  product?: any = null;
  step?: any = null;
  inventory?: any = null;
  type?: any = null;
  category?: any = null;
  stockStatus?: any = null;
}

export type ImportExcelDescription = {
  name: string;
  description: string;
  example: string;
};

export class BillingAddressModel {
  address: string | null = "";
  city: string | null = "";
  zip: string | null = "";
  country: string | null = "";
}
export class InvoiceModel {
  _id: undefined | null = undefined;
  orderNumber: number | null = 0;
  status = "unpaid";
  type = "invoice";
  customerName: string | null = "";
  customerEmail: string | null = "";
  companyVATNumber: string | null = "";
  billingMethod: string | null = "";
  billingAddress: BillingAddressModel | null = null;
  products: any = [];
  bexioId: string | null = "";
  pathName: string | null = "";
}

import { Button, Divider, Menu, MenuItem } from "@mui/material";
import DividerWithLabel from "../GenericComponents/DividerWithLabel";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import { Vocabulary } from "../../Utils/Vocabulary";
import { OrderContext } from "../../Contexts/OrderContext";
import { useContext, useState } from "react";
import { priorityArray } from "./OrderHeader";
import { postData } from "../../Services/postData";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";

export default function OrderSettingsButton() {
  const orderContext = useContext(OrderContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  /////////////////////////// SETTINGS MENU ///////////////////////////

  /**
   *
   * @param event
   */
  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }
  /**
   *
   */
  function handleCloseMenu() {
    setAnchorEl(null);
  }
  /////////////////////////// END SETTINGS MENU ///////////////////////////

  /////////////////////////// CHANGE ORDER PRIORITY ///////////////////////////

  /**
   *
   * @param priority
   */
  function handleChangePriority(priority: string) {
    const newOrder = Object.assign({}, orderContext.order);
    newOrder.priority = priority;
    orderContext.setOrder(newOrder);
  }
  /////////////////////////// END CHANGE ORDER PRIORITY ///////////////////////////

  /////////////////////////// DUPLICATE ORDER ///////////////////////////

  /**
   *
   */
  async function handleDuplicateOrder() {
    //duplicateOrder
    if (orderContext.order && orderContext.order._id)
      await postData(
        `${urlEnum.orders}/duplicateOrder/${orderContext.order._id}`
      ).then((res) => {
        if (
          res &&
          res.data &&
          (res.data.status === 200 || res.data.status === 201)
        ) {
          window.open(
            `${localUrlEnum.orders}/${res.data.result._id}`,
            "_blank"
          );
        }
      });
  }
  /////////////////////////// END DUPLICATE ORDER ///////////////////////////

  /////////////////////////// CLOSE ORDER //////////////////////////
  /**
   *
   */
  async function handleCloseOrder() {
    const res = await postData(
      `${urlEnum.orders}/close/${orderContext.order._id}`
    );
    if (res && res.data && res.data.result) {
      orderContext.setOrder(res.data.result);
    }
  }
  /////////////////////////// END CLOSE ORDER //////////////////////////

  return (
    <>
      <Button
        size="medium"
        variant="outlined"
        style={{ color: "#333", borderColor: "#333", padding: "5px 0px" }}
        onClick={handleClick}
      >
        <SettingsRoundedIcon fontSize="medium" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <DividerWithLabel label={Vocabulary.priority} />
        {priorityArray.map((item, index) => (
          <MenuItem
            key={item.name}
            onClick={() => handleChangePriority(item.name)}
            style={
              item.name === orderContext.order.priority
                ? { backgroundColor: "#f7f8fa" }
                : {}
            }
          >
            {item.icon} {item.label}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          onClick={handleDuplicateOrder}
          disabled={orderContext.order?._id ? false : true}
        >
          {Vocabulary.duplicateOrder}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={handleCloseOrder}
          disabled={orderContext.order?._id ? false : true}
        >
          {Vocabulary.closeOrder}
        </MenuItem>
      </Menu>
    </>
  );
}

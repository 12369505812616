/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useContext, useEffect, useRef, useState } from "react";
import { BillingAddressModel, InvoiceModel } from "../../Utils/Models";
import { handleChangeAutocomplete, onChangeTextField } from "../../Utils/Utils";
import { Autocomplete, Divider } from "@mui/material";
import {
  invoiceBillingMethods,
  invoiceBillingMethodsArray,
  invoiceStatuses,
  invoiceTypes,
} from "../../Utils/AutocompleteUtils";
import { OrderContext } from "../../Contexts/OrderContext";
import DividerWithLabel from "../GenericComponents/DividerWithLabel";
import { postData } from "../../Services/postData";
import { urlEnum } from "../../Utils/UrlEnum";
import { addOrUpdateOrder } from "./Order";

type InvoiceModalProps = {
  type: string;
  open: boolean;
  title: string;
  onClose: () => void;
};

export default function InvoiceModal(props: InvoiceModalProps) {
  const { type, open, title, onClose } = props;
  const orderContext = useContext(OrderContext);
  const ref: any = useRef();
  const [invoice, setInvoice] = useState(new InvoiceModel());
  const [pressOnSubmit, setPressOnSubmit] = useState(false);

  useEffect(
    () => {
      const newInvoice = new InvoiceModel();
      newInvoice.orderNumber = orderContext.order.fixablyId
        ? orderContext.order.fixablyId
        : orderContext.order.orderNumber;
      newInvoice.status =
        type === invoiceTypes.receipt
          ? invoiceStatuses.paid
          : invoiceStatuses.unpaid;
      newInvoice.type = type;
      newInvoice.customerName =
        `${orderContext.order.customer?.firstName} ${orderContext.order.customer?.lastName}` ||
        "";
      newInvoice.customerEmail = orderContext.order.customer?.email || "";
      newInvoice.products =
        orderContext.order.lines.filter(
          (line) => +line.totalCharged !== +line.total
        ) || [];
      setInvoice(newInvoice);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderContext.order]
  );
  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  /**
   *
   * @param event
   * @param model
   * @returns
   */
  function onChangeBillingAddress(event: any, model: any) {
    const newModel: any = Object.assign({}, model);
    if (!newModel.billingAddress) {
      newModel.billingAddress = new BillingAddressModel();
    }
    newModel.billingAddress[event.target.name] = event.target.value;
    return newModel;
  }

  /**
   *
   */
  function showDataDependingOnBillingMethod() {
    switch (invoice.billingMethod) {
      case invoiceBillingMethods.email:
        return (
          <TextValidator
            size="small"
            id="customerEmail"
            name="customerEmail"
            label={Vocabulary.customerEmail}
            fullWidth
            variant="outlined"
            value={invoice.customerEmail}
            onChange={(event) => setInvoice(onChangeTextField(event, invoice))}
            style={{ marginTop: 15 }}
            validators={["required"]}
            errorMessages={[Vocabulary.requiredField]}
          />
        );
      case invoiceBillingMethods.post:
        return (
          <div>
            <TextValidator
              size="small"
              id="address"
              name="address"
              label={Vocabulary.address}
              fullWidth
              variant="outlined"
              value={invoice?.billingAddress?.address}
              onChange={(event) =>
                setInvoice(onChangeBillingAddress(event, invoice))
              }
              style={{ marginTop: 15 }}
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
            />
            <TextValidator
              size="small"
              id="city"
              name="city"
              label={Vocabulary.city}
              fullWidth
              variant="outlined"
              value={invoice?.billingAddress?.city}
              onChange={(event) =>
                setInvoice(onChangeBillingAddress(event, invoice))
              }
              style={{ marginTop: 15 }}
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
            />
            <TextValidator
              size="small"
              id="zip"
              name="zip"
              label={Vocabulary.zip}
              fullWidth
              variant="outlined"
              value={invoice?.billingAddress?.zip}
              onChange={(event) =>
                setInvoice(onChangeBillingAddress(event, invoice))
              }
              style={{ marginTop: 15 }}
            />
            <TextValidator
              size="small"
              id="country"
              name="country"
              label={Vocabulary.country}
              fullWidth
              variant="outlined"
              value={invoice?.billingAddress?.country}
              onChange={(event) =>
                setInvoice(onChangeBillingAddress(event, invoice))
              }
              style={{ marginTop: 15 }}
            />
          </div>
        );
      default:
        return null;
    }
  }

  /**
   *
   */
  async function createNewInvoice() {
    orderContext.order.lines = orderContext.order.lines.map((line: any) => {
      return { ...line, totalCharged: line.total, totalRemaining: 0 };
    });
    await addOrUpdateOrder(orderContext);
    await postData(urlEnum.invoices, invoice);
    onClose();
  }

  return (
    <ValidatorForm ref={ref} onSubmit={createNewInvoice}>
      <AddOrUpdateModel
        open={open}
        title={title}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <TextValidator
          size="small"
          id="customerName"
          name="customerName"
          label={Vocabulary.customerName}
          fullWidth
          variant="outlined"
          value={invoice.customerName}
          onChange={(event) => setInvoice(onChangeTextField(event, invoice))}
          style={{ marginTop: 15 }}
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
        />
        {type === invoiceTypes.receipt ? null : (
          <>
            <TextValidator
              size="small"
              id="companyVATNumber"
              name="companyVATNumber"
              label={Vocabulary.companyVATNumber}
              fullWidth
              variant="outlined"
              value={invoice.companyVATNumber}
              onChange={(event) =>
                setInvoice(onChangeTextField(event, invoice))
              }
              style={{ marginTop: 15 }}
            />
            <Autocomplete
              size="small"
              value={invoice.billingMethod}
              options={invoiceBillingMethodsArray}
              getOptionLabel={(option: any) => Vocabulary[option]}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="billingMethod"
                  label={Vocabulary.billingMethod}
                  value={invoice.billingMethod}
                  validators={["required"]}
                  errorMessages={[Vocabulary.requiredField]}
                />
              )}
              onChange={(e: any, value: any) => {
                const test = handleChangeAutocomplete(
                  e,
                  value,
                  invoice,
                  "billingMethod"
                );
                setInvoice(test);
              }}
              style={{ marginTop: 15 }}
            />
            {showDataDependingOnBillingMethod()}
          </>
        )}
        <DividerWithLabel
          label={Vocabulary.products}
          styles={{ marginTop: 15 }}
        />
        {invoice.products.map((line: any) => {
          return (
            <div key={line._id}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>
                  {line.product?.code} -{" "}
                  {line.product?.name?.length > 30
                    ? `${line.product?.name.substring(0, 30)}...`
                    : line.product?.name}{" "}
                  x {line.quantity}
                </p>
                <p>{line.total}</p>
              </div>
              <Divider />
            </div>
          );
        })}
        <p
          style={{
            textAlign: "right",
            fontSize: 20,
            fontWeight: 600,
            marginTop: 10,
          }}
        >
          {Vocabulary.total}: &nbsp;&nbsp;
          {invoice.products
            .reduce((a: any, b: any) => +a + +b.total, 0)
            .toFixed(2)}
        </p>
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}

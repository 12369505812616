/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from "../Styles/dashboard.module.css";
import MainTable from "../Components/GenericComponents/MainTable";
import { urlEnum } from "../Utils/UrlEnum";
import { Chip, useMediaQuery } from "@mui/material";
import { Vocabulary } from "../Utils/Vocabulary";
import { invoiceTypes } from "../Utils/AutocompleteUtils";

export default function Receipts() {
  const phoneStyle = useMediaQuery("(max-width: 800px)");

  const receiptHeader = [
    {
      label: Vocabulary.orderNumber,
      name: "orderNumber",
      options: { sort: false },
    },
    {
      label: Vocabulary.customerName,
      name: "customerName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.products,
      name: "products",
      options: {
        sort: false,
        customBodyRender: (products: any) => {
          return products.map((product: any) => (
            <Chip
              label={
                <>
                  {product?.product?.code} -{" "}
                  {product?.product?.name?.length > 20
                    ? `${product?.product?.name?.substring(0, 20)}...`
                    : product?.product?.name}
                </>
              }
              style={{
                backgroundColor: "#404a694d",
              }}
            />
          ));
        },
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: false,
        customBodyRender: (status: any) => {
          return <>{Vocabulary[status]}</>;
        },
      },
    },
  ];

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <MainTable
        urlEnumApi={`${urlEnum.invoices}/${invoiceTypes.receipt}`}
        titleDelete={""}
        textDelete={""}
        header={receiptHeader}
        tableTitle={Vocabulary.receiptsList}
        hideAddButton={true}
        hideOptionButtons={true}
      />
    </div>
  );
}

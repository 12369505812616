/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from "../Styles/dashboard.module.css";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import MainTable from "../Components/GenericComponents/MainTable";
import { localUrlEnum, urlEnum } from "../Utils/UrlEnum";
import { Button, Chip, Tab, Tabs, useMediaQuery } from "@mui/material";
import { Vocabulary } from "../Utils/Vocabulary";
import { invoiceStatuses, invoiceTypes } from "../Utils/AutocompleteUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import theme from "../Themes/Theme";
import { styleForTableWithTabs } from "./Users";
import GenericConfirmModal from "../Components/GenericComponents/GenericConfirmModal";
import { updateData } from "../Services/updateData";
import { download, getDocExtension } from "../Utils/Utils";

export default function Invoices() {
  const navigate = useNavigate();
  const location = useLocation();
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [value, setValue] = useState(0);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [markAsPaid, setMarkAsPaid] = useState({
    openModal: false,
    invoiceId: null,
  });

  const invoiceHeader = [
    {
      label: Vocabulary.orderNumber,
      name: "orderNumber",
      options: { sort: false },
    },
    {
      label: Vocabulary.customerName,
      name: "customerName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.products,
      name: "products",
      options: {
        sort: false,
        customBodyRender: (products: any) => {
          return products.map((product: any) => (
            <Chip
              label={
                <>
                  {product?.product?.code} -{" "}
                  {product?.product?.name?.length > 20
                    ? `${product?.product?.name?.substring(0, 20)}...`
                    : product?.product?.name}
                </>
              }
              style={{
                backgroundColor: "#404a694d",
              }}
            />
          ));
        },
      },
    },
    {
      label: Vocabulary.file,
      name: "pathName",
      options: {
        sort: false,
        customBodyRender: (pathName: any) => {
          if (!pathName) return null;
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleClick(pathName);
              }}
            >
              {Vocabulary.download}
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    const search: any = location.search.split(/[?&]+/);
    const newFilters: any = {
      status: null,
    };

    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        if (values[1] === "null") newFilters[values[0]] = null;
        if (values[1].includes("+"))
          newFilters[values[0]] = values[1].replace("+", " ");
      }
    });
    // setFilters(newFilters);
    setValueFromLocation(newFilters.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param tabValue
   * @returns
   */
  function getTabStyle(tabValue: number) {
    return {
      backgroundColor:
        value === tabValue
          ? theme.palette.secondary.main
          : theme.palette.cancel?.main,
      color: value === tabValue ? "white" : "#9c9b9b",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      marginRight: "1px",
    };
  }

  /**
   *
   * @param attachment
   */
  async function handleClick(fileName: any) {
    const token = localStorage.getItem("access_token");
    const type = getDocExtension(fileName.split(".").pop());

    fetch(`${urlEnum.invoices}/download/${fileName}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((response) => {
        download(response, fileName.split("/").pop(), type);
      });
  }
  /**
   *
   * @param newValue
   */
  function setValueFromLocation(newValue: string) {
    switch (newValue) {
      case invoiceStatuses.unpaid:
        setValue(0);
        break;
      case invoiceStatuses.paid:
        setValue(1);
        break;
      default:
        setValue(0);
        navigate(`${localUrlEnum.invoices}?status=${invoiceStatuses.unpaid}`);
        break;
    }
  }

  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeValue(event: React.SyntheticEvent, newValue: number) {
    switch (newValue) {
      case 0:
        navigate(`${localUrlEnum.invoices}?status=${invoiceStatuses.unpaid}`);
        break;
      case 1:
        navigate(`${localUrlEnum.invoices}?status=${invoiceStatuses.paid}`);
        break;
      default:
        navigate(`${localUrlEnum.invoices}?status=${invoiceStatuses.unpaid}`);
        break;
    }
  }

  /**
   *
   */
  async function markInvoiceAsPaid() {
    await updateData(`${urlEnum.invoices}/${markAsPaid.invoiceId}`);
    setMarkAsPaid({
      openModal: false,
      invoiceId: null,
    });
    setShouldUpdate(true);
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <Tabs
        value={value}
        onChange={handleChangeValue}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
        style={{ width: "100%" }}
      >
        <Tab label={Vocabulary.unpaid} style={getTabStyle(0)} />
        <Tab label={Vocabulary.paid} style={getTabStyle(1)} />
      </Tabs>
      <MainTable
        urlEnumApi={`${urlEnum.invoices}/${invoiceTypes.invoice}`}
        titleDelete={""}
        textDelete={""}
        header={invoiceHeader}
        tableTitle={Vocabulary.invoicesList}
        hideAddButton={true}
        hideDeleteButton={true}
        hideEditButton={true}
        shouldUpdate={shouldUpdate}
        anotherButtonsOptions={{
          title: Vocabulary.options,
          icon: (
            <>
              <BookmarkAddedIcon style={{ marginRight: 5 }} />
              {Vocabulary.markPaid}
            </>
          ),
          condition: (item) => {
            return item.type === invoiceTypes.invoice &&
              item.status === invoiceStatuses.unpaid
              ? true
              : false;
          },
          action: (item) => {
            setShouldUpdate(false);
            setMarkAsPaid({
              openModal: true,
              invoiceId: item._id,
            });
          },
        }}
        style={styleForTableWithTabs}
      />
      {markAsPaid.openModal ? (
        <GenericConfirmModal
          openDeleteModal={markAsPaid.openModal}
          title={Vocabulary.markPaid}
          text={Vocabulary.markPaidMessage}
          onClose={() =>
            setMarkAsPaid({
              openModal: false,
              invoiceId: null,
            })
          }
          deleteItem={markInvoiceAsPaid}
        />
      ) : null}
    </div>
  );
}

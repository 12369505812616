import Config from "./Config";

export const urlEnum = {
  login: `${Config.apiUrl}/auth/login`,
  logout: `${Config.apiUrl}/auth/signout`,
  register: `${Config.apiUrl}/auth/register`,
  users: `${Config.apiUrl}/user`,
  workflows: `${Config.apiUrl}/workflows`,
  steps: `${Config.apiUrl}/workflowStep`,
  statuses: `${Config.apiUrl}/statuses`,
  orders: `${Config.apiUrl}/orders`,
  trashedOrders: `${Config.apiUrl}/trashedOrders`,
  products: `${Config.apiUrl}/products`,
  devices: `${Config.apiUrl}/devices`,
  customers: `${Config.apiUrl}/customers`,
  deliveries: `${Config.apiUrl}/deliveries`,
  lines: `${Config.apiUrl}/lines`,
  locations: `${Config.apiUrl}/locations`,
  notes: `${Config.apiUrl}/notes`,
  shipments: `${Config.apiUrl}/shipments`,
  roles: `${Config.apiUrl}/roles`,
  reports: `${Config.apiUrl}/reports`,
  ordersReports: `${Config.apiUrl}/ordersReports`,
  workflowsReports: `${Config.apiUrl}/workflowsReports`,
  attributes: `${Config.apiUrl}/productAttributes`,
  categories: `${Config.apiUrl}/productCategories`,
  cronjob: `${Config.apiUrl}/cronjob`,
  emailTemplates: `${Config.apiUrl}/emailTemplates`,
  documentsTemplates: `${Config.apiUrl}/documentsTemplates`,
  inventories: `${Config.apiUrl}/inventories`,
  stockTransfers: `${Config.apiUrl}/stockTransfers`,
  purchaseOrders: `${Config.apiUrl}/purchaseOrders`,
  taxRates: `${Config.apiUrl}/taxRates`,
  emailSettings: `${Config.apiUrl}/emailSettings`,
  timeTracking: `${Config.apiUrl}/timeTracking`,
  otherReports: `${Config.apiUrl}/otherReports`,
  invoices: `${Config.apiUrl}/invoices`,
};

export const localUrlEnum = {
  login: "/login",
  users: "/users",
  workflow: "/workflow",
  workflows: "/workflows",
  products: "/products",
  steps: "/steps",
  meetings: "/meetings",
  media: "/media",
  settings: "/settings",
  myAccount: "/myAccount",
  departments: "/departments",
  product: "/product",
  messages: "/messages",
  task: "/task",
  report: "/report",
  dashboard: "/",
  orders: "/orders",
  devices: "/devices",
  customers: "/customers",
  deliveries: "/deliveries",
  lines: "/lines",
  locations: "/locations",
  notes: "/notes",
  shipments: "/shipments",
  statuses: "/statuses",
  roles: "/roles",
  reports: "/reports",
  ordersReports: "/ordersReports",
  workflowsReports: "/workflowsReports",
  productSettings: "/productSettings",
  trashedOrders: "/trashedOrders",
  emailTemplates: "/emailTemplates",
  stocksManagement: "/stocksManagement",
  purchaseOrders: "/purchaseOrders",
  timeTracking: "/timeTracking",
  otherReports: "/otherReports",
  invoices: "/invoices",
  receipts: "/receipts",
};

export const basicOperationsEnum = {
  create: "/create",
  update: "/update",
  delete: "/delete",
  import: "/import",
  export: "/export",
};

/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from "../../Styles/dashboard.module.css";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LineModel, NotesModel, OrderModel } from "../../Utils/Models";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import OrderDataComponents from "./OrderDataComponents";
import { postData } from "../../Services/postData";
import OrderHeader from "./OrderHeader";
import { updateData } from "../../Services/updateData";
import { OrderContext } from "../../Contexts/OrderContext";
import { useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { Vocabulary } from "../../Utils/Vocabulary";

export type EditNoteType = {
  index: number;
  note: NotesModel | null;
  filter: Array<string>;
};

export async function addOrUpdateOrder(orderContext: any) {
  const linesWithoutProducts = orderContext.order.lines.filter(
    (line: any) => !line.product
  );
  if (linesWithoutProducts.length > 0) {
    toast.error(Vocabulary.saveOrderNoProductMessage);
    return;
  }
  const newOrder: any = structuredClone(orderContext.order);
  newOrder.notes = newOrder.notes.map((note: NotesModel) => {
    return {
      _id: note._id,
      title: note.title || "",
      text: note.text || "",
      visibility: note.visibility ? note.visibility.name : "",
      type: note.type || "",
      status: note.status || 0,
      report: note.report,
      createdBy: note.createdBy,
      createdAt: note.createdAt,
    };
  });
  newOrder.lines = newOrder.lines.map((line: any) => {
    const newTotalRemaining =
      (+line.total || 0) - (+line.totalCharged || 0) || 0;
    return { ...line, totalRemaining: newTotalRemaining.toFixed(2) };
  });
  newOrder.totalRemaining = newOrder.lines
    .reduce((accumulator: any, line: any) => {
      return +accumulator + +line.totalRemaining;
    }, 0)
    .toFixed(2);
  newOrder.total = newOrder.lines
    .reduce((accumulator: any, line: any) => {
      return +accumulator + +line.total;
    }, 0)
    .toFixed(2);
  newOrder.total0 = newOrder.lines
    .reduce((accumulator: any, line: any) => {
      return +accumulator + +line.total0;
    }, 0)
    .toFixed(2);
  //
  if (newOrder?._id) {
    const res = await updateData(`${urlEnum.orders}/${newOrder._id}`, newOrder);
    if (res && res.data && res.data.result) {
      orderContext.setOrder(res.data.result);
    }
  } else {
    const userId = localStorage.getItem("userId");
    newOrder.handledBy = userId ? userId : null;
    const res = await postData(urlEnum.orders, newOrder);
    if (res) {
      window.location.href = `${localUrlEnum.orders}/${res.data.result._id}`;
    }
  }
}
export default function Order() {
  const location = useLocation();
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [notesBackup, setNotesBackup] = useState<Array<NotesModel>>([]);
  const orderContext = useContext(OrderContext);

  /**
   *
   */
  useEffect(() => {
    const id: string = location.pathname.split("/").pop() || "";
    if (id === "newOrder") orderContext.setOrder(new OrderModel());
    else {
      getOrder(id);
      getEmailsAndSaveThem(id);
    }

    // // event listener for scroll
    // const div: any = document.getElementById("parentOrder");
    // div.addEventListener("scroll", handleScroll);
    // return () => {
    //   div.removeEventListener("scroll", handleScroll);
    // };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /////////////////////////// GET SCROLL TOP ///////////////////////////
  /**
   *
   */
  // const handleScroll = (event: any) => {
  //   const { scrollTop } = event.target;
  //   const orderTopMenu: any = document.getElementById("orderTopMenu");
  //   orderTopMenu.style.top = `${scrollTop}px`;
  // };

  /////////////////////////// GET ORDER ///////////////////////////
  /**
   *
   * @param id
   */
  function getOrder(id: string) {
    getData(`${urlEnum.orders}/${id}`)
      .then((res) => {
        if (res) {
          const resOrder = res.data.result;
          if (resOrder.lines && resOrder.lines.length === 0)
            resOrder.lines = [new LineModel()];
          if (resOrder.notes && resOrder.notes.length !== 0)
            resOrder.notes = resOrder.notes.reverse();
          setNotesBackup(resOrder.notes);
          orderContext.setOrder(res.data.result);
        }
      })
      .catch((err) => {
        //
      });
  }

  /**
   *
   * @param id
   */
  function getEmailsAndSaveThem(id: string) {
    getData(`${urlEnum.orders}/getEmails/${id}`);
  }

  /////////////////////////// END GET ORDER ///////////////////////////

  /////////////////////////// UPDATE ORDER ///////////////////////////
  /**
   *
   * @param step
   */

  /**
   *
   */

  /////////////////////////// END UPDATE ORDER ///////////////////////////

  return (
    <div
      id="parentOrder"
      className={
        phoneStyle ? styles.mainContainerOrderMobile : styles.mainContainerOrder
      }
    >
      <OrderHeader />
      <OrderDataComponents notesBackup={notesBackup} />
    </div>
  );
}
